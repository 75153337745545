<app-request-payment-details [data$]="policyDetail$">
  <div mobile>
    <ng-container *ngTemplateOutlet="mobileRight"></ng-container>
  </div>
  <div desktop>
    <ng-container *ngTemplateOutlet="desktopRight"></ng-container>
  </div>
</app-request-payment-details>

<ng-template #mobileRight>
  <div class="mt-lg">
    <app-section-title
      [title]="'orderSummary.title' | translate"
    ></app-section-title>
  </div>
  <ng-container *ngTemplateOutlet="priceListDetail"> </ng-container>
  <ng-container *ngTemplateOutlet="paymentOption"> </ng-container>
</ng-template>

<ng-template #desktopRight>
  <app-expandable-card [title]="'orderSummary.title' | translate">
    <ng-container snippet>
      <ng-container *ngTemplateOutlet="orderSummarySnippet"></ng-container>
    </ng-container>
    <ng-container hidden>
      <ng-container *ngTemplateOutlet="priceList"> </ng-container>
    </ng-container>
    <ng-container share>
      <ng-container *ngTemplateOutlet="paymentOption"></ng-container>
    </ng-container>
  </app-expandable-card>
</ng-template>

<ng-template #orderSummarySnippet>
  <div class="order-summary-container">
    <div class="content-wrapper remaining-amount-container">
      <div class="left mt-md">
        {{ "topup.topupAmount" | translate }}
      </div>
      <div class="right mt-md">
        RM {{ getTopupAmount$() | async | number : "1.2-2" }}
      </div>
      <div class="left">
        {{ "topup.serviceCharge" | translate }}
      </div>
      <div class="right">
        RM {{ getServiceCharge$() | async | number : "1.2-2" }}
      </div>
      <div class="divider"></div>
      <div class="left">
        {{ "topup.remainingAmount" | translate }}
      </div>
      <div class="right">
        <p class="remaining-amount pt-sm">
          RM {{ getUnpaidRemainingAmount$() | async | number : "1.2-2" }}
        </p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #priceList>
  <img
    [src]="(topupUserData$ | async)?.policyDetail?.productImage"
    class="selected-insurer"
    alt=""
  />
  <mat-expansion-panel
    class="summary-expand"
    (click)="$event.stopPropagation()"
  >
    <mat-expansion-panel-header
      [collapsedHeight]="'100%'"
      [expandedHeight]="'100%'"
    >
      <mat-panel-title>
        {{ "quotation.benefits" | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="expand-content">
      <app-benefit
        [code]="(topupUserData$ | async)?.policyDetail?.productName"
      ></app-benefit>
    </div>
  </mat-expansion-panel>
  <ng-container *ngIf="topupUserData$ | async">
    <ng-container
      *ngTemplateOutlet="
        priceListDetail;
        context: { topupUserData: topupUserData$ | async }
      "
    >
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #priceListDetail>
  <ng-container *ngIf="topupUserData$ | async as topupUserData">
    <div *ngIf="topupUserData?.topUpRequest?.type !== 'PPP'">
      <div class="content-wrapper">
        <div class="left">
          <div class="label-desc">
            {{ "common.sumInsured" | translate }} (SI)
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM {{ topupUserData?.policyDetail?.sumInsured | number : "1.2-2" }}
          </div>
        </div>
        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.basePremium" | translate }}
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM {{ topupUserData?.policyDetail?.basePremium | number : "1.2-2" }}
          </div>
        </div>
        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.noClaimDiscount" | translate }} <br />
            <b
              >({{
                topupUserData?.policyDetail?.ncd * 100 | number : "1.0-2"
              }}%)</b
            >
            <b *ngIf="!topupUserData?.policyDetail?.ncd"> - </b>
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right color-red">
            - RM
            {{ getNCD$() | async | number : "1.2-2" }}
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="content-wrapper">
        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.totalBasePremium" | translate }}
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM {{ getBasePremium$() | async | number : "1.2-2" }}
          </div>
        </div>
      </div>

      <div class="content-wrapper">
        <div class="mt-xs mb-sm" *ngIf="topupUserData?.policyBundle?.length">
          <span class="cover-label">{{
            "orderSummary.specialPackage" | translate
          }}</span>
        </div>

        <ng-container *ngFor="let item of topupUserData?.policyBundle">
          <div class="left">
            <div class="label-desc">
              {{ item?.description }}
            </div>
          </div>
          <div class="right">
            <div class="label-value text-right">
              RM {{ item?.amount | number : "1.2-2" }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="content-wrapper">
        <div
          class="mt-xs mb-sm"
          *ngIf="showAddOnsLabelInReciept(topupUserData)"
        >
          <span class="cover-label">{{
            "orderSummary.addOnsCapital" | translate
          }}</span>
        </div>

        <!-- Bundle AddOns here -->
        <ng-container *ngFor="let item of topupUserData?.policyBundleAddOn">
          <div class="left">
            <div class="label-desc">
              {{ item?.description }}
            </div>
          </div>
          <div class="right">
            <div class="label-value text-right">
              RM {{ item?.amount | number : "1.2-2" }}
            </div>
          </div>
        </ng-container>

        <!-- AddOns here -->
        <ng-container *ngFor="let item of topupUserData?.addOn">
          <ng-container *ngIf="!item.hidePriceBreakdown">
            <div class="left">
              <div class="label-desc">
                {{ "addOn." + item.code | translate }}
              </div>
            </div>
            <div class="right">
              <ng-container [ngSwitch]="item.code">
                <ng-container *ngSwitchCase="'Windshield'">
                  <div class="label-value text-right">
                    RM
                    {{ item.amount | number : "1.2-2" }}
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'AdditionalDrivers'">
                  <div class="label-value text-right">
                    RM
                    {{ item.amount | number : "1.2-2" }}
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="label-value text-right">
                    RM
                    {{ item.amount | number : "1.2-2" }}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <mat-divider></mat-divider>

      <mat-divider></mat-divider>

      <div class="content-wrapper">
        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.totalGrossPremium" | translate }}
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM
            {{ getGrossPremium$() | async | number : "1.2-2" }}
          </div>
        </div>
        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.serviceTax" | translate }}
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM
            {{ getTax$() | async | number : "1.2-2" }}
          </div>
        </div>

        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.stampDuty" | translate }}
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM
            {{ topupUserData?.policyDetail?.stampDutyPrice | number : "1.2-2" }}
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="content-wrapper">
        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.totalNetPremium" | translate }}
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM
            {{ getTotalNetPremium$() | async | number : "1.2-2" }}
          </div>
        </div>

        <ng-container *ngIf="topupUserData?.policyDetail?.platformPrice">
          <div class="left">
            <div class="label-desc">
              {{ "orderSummary.platformOffer" | translate }}
            </div>
          </div>
          <div class="right">
            <div class="label-value text-right color-red">
              - RM
              {{ getPlatformDiscountRate$() | async | number : "1.2-2" }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="topupUserData?.policyDetail?.includeRoadTax">
          <div class="left">
            <div class="label-desc">
              {{ "addOn.roadTax" | translate }}
            </div>
          </div>
          <div class="right">
            <div class="label-value text-right">
              RM
              {{ topupUserData?.policyDetail?.roadTaxPrice | number : "1.2-2" }}
            </div>
          </div>
          <div class="left">
            <div
              class="label-desc"
              *ngIf="topupUserData?.policyDetail?.includeDigitalRoadTax"
            >
              {{ "digitalRoadTaxFee" | translate }}
            </div>
            <div
              class="label-desc"
              *ngIf="!topupUserData?.policyDetail?.includeDigitalRoadTax"
            >
              {{ "addOn.roadTaxDelivery" | translate }}
            </div>
          </div>
          <div class="right">
            <div
              class="label-value text-right color-red"
              *ngIf="topupUserData?.policyDetail?.includeDigitalRoadTax"
            >
              RM
              {{
                topupUserData?.policyDetail?.digitalRoadTaxFee
                  | number : "1.2-2"
              }}
            </div>
            <div
              class="label-value text-right"
              *ngIf="!topupUserData?.policyDetail?.includeDigitalRoadTax"
            >
              RM
              {{
                topupUserData?.policyDetail?.roadTaxDeliveryPrice
                  | number : "1.2-2"
              }}
            </div>
          </div>
        </ng-container>
      </div>

      <mat-divider></mat-divider>

      <div class="content-wrapper">
        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.totalCharges" | translate }}
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM {{ getTotalCharge$() | async | number : "1.2-2" }}
          </div>
        </div>
        <!-- PROMO SECTION -->
        <ng-container *ngIf="topupUserData?.policyDetail?.promoCodePrice">
          <div class="left">
            <div class="label-desc">Promo</div>
          </div>
          <div class="right">
            <div class="label-value text-right color-red">
              - RM {{ getTotalPromo$() | async | number : "1.2-2" }}
            </div>
          </div>
        </ng-container>

        <div class="left">
          <div class="label-desc">
            {{ "orderSummary.serviceFee" | translate }}
          </div>
        </div>
        <div class="right">
          <div class="label-value text-right">
            RM {{ getServiceFee$() | async | number : "1.2-2" }}
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>
    </div>

    <div class="content-wrapper mt-md">
      <div class="left">
        <div class="label-desc">
          {{ "topup.totalRevised" | translate }}
          <span class="bold">
            ({{
              "topup.totalRevisedNote" + topupUserData?.topUpRequest?.type
                | translate
            }})</span
          >
        </div>
      </div>
      <div class="right">
        <div class="label-value text-right">
          RM {{ getTotal$() | async | number : "1.2-2" }}
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="left">
        <div class="label-desc">{{ "topup.totalPaid" | translate }}</div>
      </div>
      <div class="right">
        <div class="label-value text-right color-dark-green">
          RM {{ getTotalPaid$() | async | number : "1.2-2" }}
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="content-wrapper remaining-amount-container">
      <div class="left mt-md">
        {{ "topup.topupAmount" | translate }}
      </div>
      <div class="right mt-md">
        RM {{ getTopupAmount$() | async | number : "1.2-2" }}
      </div>
      <div class="left">
        {{ "topup.serviceCharge" | translate }}
      </div>
      <div class="right">
        RM {{ getServiceCharge$() | async | number : "1.2-2" }}
      </div>
      <div class="divider"></div>
      <div class="left">
        {{ "topup.remainingAmount" | translate }}
      </div>
      <div class="right">
        <p class="remaining-amount pt-sm">
          RM {{ getUnpaidRemainingAmount$() | async | number : "1.2-2" }}
        </p>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #paymentOption>
  <div class="pt-xs" (click)="$event.stopPropagation()">
    <app-section-title
      [title]="'paymentOptions.title' | translate"
    ></app-section-title>
  </div>
  <app-payment-options
    [totalPrice]="getUnpaidRemainingAmount$() | async"
    [totalPriceBeforeCharges]="getUnpaidRemainingAmount$() | async"
    [bankCharge]="null"
    [paymentFormGroup]="paymentFormGroup"
    [onlyFullPayment]="true"
  ></app-payment-options>
  <button
    mat-flat-button
    [disabled]="!paymentFormGroup?.valid"
    (click)="$event.stopPropagation(); handleSubmit()"
    class="btn-primary"
  >
    {{ "orderSummary.readyToPay" | translate }}
  </button>
</ng-template>
