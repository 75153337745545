import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AnimationOptions, LottieComponent } from "ngx-lottie";
import { LoadingOverlayService } from "../../services/loading-overlay.service";

@Component({
  selector: "app-loading-overlay",
  templateUrl: "./loading-overlay.component.html",
  styleUrls: ["./loading-overlay.component.scss"],
  standalone: true,
  imports: [CommonModule, LottieComponent],
})
export class LoadingOverlayComponent implements OnInit {
  constructor(public loadingService: LoadingOverlayService) {}

  ngOnInit(): void {}

  animationOptions: AnimationOptions = {
    path: "../../assets/animations/ps_loading_animation.json", // Path to your animation JSON file
  };
}
