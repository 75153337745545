<div class="request-payment">
  <div class="request-payment__left">
    <div class="request-payment__left__title">
      {{ "orderSummary.titleRevised" | translate }}
    </div>
    <div class="request-payment__left__desc">
      {{ "orderSummary.desc" | translate }}
    </div>
    <!-- Car Protection Plan -->
    <div class="mt-lg mr-lg">
      <app-section-title
        title="orderSummary.carProtectionPlan"
      ></app-section-title>
    </div>
    <div class="request-payment__left__cpp">
      <div class="request-payment__left__cpp__img">
        <img
          [src]="(data$ | async)?.image || (data$ | async)?.productImage"
          alt=""
        />
      </div>
      <div>
        <div>
          <div class="request-payment__left__cpp__title">
            {{ "orderSummary.insuranceProvider" | translate }}:
          </div>
          <div class="request-payment__left__cpp__value">
            {{ (data$ | async)?.productName || "-" }}
          </div>
        </div>
        <div class="pt-sm">
          <div class="request-payment__left__cpp__title">
            {{ "forms.typeOfInsurance" | translate }}:
          </div>
          <div class="request-payment__left__cpp__value">
            {{ (data$ | async)?.insuranceType || "-" }}
          </div>
        </div>
        <div class="pt-sm">
          <div class="request-payment__left__cpp__title">
            {{ "common.sumInsured" | translate }}:
          </div>
          <div class="request-payment__left__cpp__value">
            {{ (data$ | async)?.sumInsured | number : "1.2-2" }}
          </div>
        </div>
        <div class="pt-sm">
          <div class="request-payment__left__cpp__title">
            {{ "orderSummary.effectivePeriod" | translate }}:
          </div>
          <div class="request-payment__left__cpp__value">
            {{ (data$ | async)?.effectiveDate | date : "dd MMM yyyy" }}
          </div>
        </div>
      </div>
    </div>
    <!-- Car details -->
    <div class="mt-lg mr-lg">
      <app-section-title title="orderSummary.carDetails"></app-section-title>
    </div>
    <app-flex-container
      [title]="'orderSummary.carNumber' | translate"
      [value]="(data$ | async)?.vehicleNo || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'orderSummary.carModel' | translate"
      [value]="
        (data$ | async)?.make +
        ' ' +
        (data$ | async)?.model +
        ' ' +
        (data$ | async)?.transmission
      "
    ></app-flex-container>
    <!-- Personal details -->
    <div class="mt-lg mr-lg">
      <app-section-title
        title="orderSummary.personalDetails"
      ></app-section-title>
    </div>
    <app-flex-container
      [title]="getNameLabel() | async | translate"
      [value]="(data$ | async)?.name || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="getIdentityNoLabel() | async | translate"
      [value]="(data$ | async)?.identityNo || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.gender' | translate"
      [value]="'forms.' + (data$ | async)?.gender | translate"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.maritalStatus' | translate"
      [value]="(data$ | async)?.maritalStatus || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.dob' | translate"
      [value]="(data$ | async)?.dob | date : 'dd MMM yyyy'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.occupation' | translate"
      [value]="(data$ | async)?.occupation || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.mobileNo' | translate"
      [value]="(data$ | async)?.mobileNo || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.email' | translate"
      [value]="(data$ | async)?.email || '-'"
    ></app-flex-container>
    <!-- Residential address -->
    <div class="mt-lg mr-lg">
      <app-section-title
        title="orderSummary.residentialAddress"
      ></app-section-title>
    </div>
    <app-flex-container
      [title]="'forms.addressLineOne' | translate"
      [value]="(data$ | async)?.address || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.addressLineTwo' | translate"
      [value]="(data$ | async)?.address2 || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.city' | translate"
      [value]="(data$ | async)?.city || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.residentialPostcode' | translate"
      [value]="(data$ | async)?.postcode || '-'"
    ></app-flex-container>
    <app-flex-container
      [title]="'forms.state' | translate"
      [value]="(data$ | async)?.state || '-'"
    ></app-flex-container>
    <!-- Delivery address -->
    <div class="mt-lg mb-lg" *ngIf="(data$ | async)?.deliveryAddress">
      <app-section-title title="forms.deliveryAddress"></app-section-title>
      <app-flex-container
        [title]="'forms.addressLineOne' | translate"
        [value]="(data$ | async)?.deliveryAddress || '-'"
      ></app-flex-container>
      <app-flex-container
        [title]="'forms.addressLineTwo' | translate"
        [value]="(data$ | async)?.deliveryAddress2 || '-'"
      ></app-flex-container>
      <app-flex-container
        [title]="'forms.city' | translate"
        [value]="(data$ | async)?.deliveryCity || '-'"
      ></app-flex-container>
      <app-flex-container
        [title]="'forms.postcode' | translate"
        [value]="(data$ | async)?.deliveryPostcode || '-'"
      ></app-flex-container>
      <app-flex-container
        [title]="'forms.state' | translate"
        [value]="(data$ | async)?.deliveryState || '-'"
      ></app-flex-container>
    </div>

    <div
      class="request-content-mobile"
      *ngIf="screenSizeService.isMobile$ | async"
    >
      <!-- Mobile Right side -->
      <ng-content select="[mobile]"></ng-content>
    </div>
  </div>
  <div
    class="request-payment__right"
    *ngIf="!(screenSizeService.isMobile$ | async)"
  >
    <!-- Desktop right side -->
    <ng-content select="[desktop]"></ng-content>
  </div>
</div>
