<FORM
	method="post"
	name="ePayment"
	action="https://payment.ipay88.com.my/ePayment/entry.asp"
>
	<INPUT type="hidden" name="MerchantCode" [value]="obj?.MerchantCode" />
	<INPUT type="hidden" name="PaymentId" />
	<INPUT type="hidden" name="RefNo" [value]="obj?.RefNo" />
	<INPUT type="hidden" name="Amount" [value]="obj?.Amount" />
	<INPUT type="hidden" name="Currency" [value]="obj?.Currency" />
	<INPUT type="hidden" name="ProdDesc" [value]="obj?.ProdDesc" />
	<INPUT type="hidden" name="UserName" [value]="obj?.CustomerName" />
	<INPUT type="hidden" name="UserEmail" [value]="obj?.CustomerEmail" />
	<INPUT type="hidden" name="UserContact" [value]="obj?.CustomerMobileNo" />
	<INPUT type="hidden" name="Remark" [value]="obj?.Remark" />
	<INPUT type="hidden" name="Lang" [value]="obj?.Lang" />
	<INPUT type="hidden" name="SignatureType" [value]="obj?.SignatureType" />
	<INPUT type="hidden" name="Signature" [value]="obj?.Signature" />
	<INPUT type="hidden" name="ResponseURL" [value]="obj?.ResponseUrl" />
	<INPUT type="hidden" name="BackendURL" [value]="obj?.BackendUrl" />

	<INPUT
		type="submit"
		value="Proceed with Payment"
		name="Submit"
		id="ipay88"
		style="display: none"
	/>
</FORM>
