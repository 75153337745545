import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-thankyou-dialog",
  templateUrl: "./thankyou-dialog.component.html",
  styleUrls: ["./thankyou-dialog.component.scss"],
  standalone: true,
  imports: [TranslateModule, MatButtonModule, MatDialogModule],
})
export class ThankyouDialogComponent {
  title: string;
  body: string;
  constructor(@Inject(MAT_DIALOG_DATA) data: any) {
    this.title = data.title;
    this.body = data.body;
  }
}
