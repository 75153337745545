import { HostListener, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScreenSizeService {
  public isMobileSubject = new BehaviorSubject<boolean>(false);

  isMobile$ = this.isMobileSubject.asObservable();

  constructor() {
    this.checkIsMobile(); // Initial check
  }

  private checkIsMobile(width: number = 960): void {
    this.isMobileSubject.next(window.innerWidth < width); // Adjust the threshold as needed
  }

  @HostListener("window:resize", ["$event"])
  notifyResize(width?: number): void {
    this.checkIsMobile(width);
  }
}
