<div class="response-bg" *ngIf="data?.status">
  <div class="response-card">
    <div class="response-card__icon">
      <mat-icon>check_circle</mat-icon>
    </div>
    <div class="response-card__main-section">
      <div class="main-title">
        {{ "response.paymentConfirmed" | translate }}
      </div>
      <div class="ref-no">
        {{ "response.referenceNo" | translate }} <span>{{ refNo }}</span>
      </div>
    </div>
    <hr />
    <div class="response-card__content-text">
      {{ "response.thankYou" | translate }} <span>{{ data.data.email }}.</span>
      <br /><br />
      {{ "response.youWillReceive" | translate }}
    </div>

    <button
      mat-raised-button
      *ngIf="data.data.refType != 'END'"
      class="btn-primary mt-md"
      (click)="goTo('/')"
    >
      {{ "response.backToHome" | translate }}
    </button>
  </div>
</div>
