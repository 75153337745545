import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private baseUrl: string = `${environment.url}/api/`;
  constructor(private http: HttpClient) {}

  get(
    endpoint: string,
    options?: { headers?: HttpHeaders; params?: HttpParams },
  ): Observable<any> {
    const fullOptions = {
      ...options,
      responseType: "json" as "json",
    };
    return this.http.get<any>(this.baseUrl + endpoint, fullOptions);
  }

  post(
    endpoint: string,
    params: any,
    options?: { headers?: HttpHeaders; params?: HttpParams },
  ): Observable<any> {
    const fullOptions = {
      ...options,
      responseType: "json" as "json",
    };
    return this.http.post<any>(this.baseUrl + endpoint, params, fullOptions);
  }

  patch(
    endpoint: string,
    params: any,
    options?: { headers?: HttpHeaders; params?: HttpParams },
  ): Observable<any> {
    const fullOptions = {
      ...options,
      responseType: "json" as "json",
    };
    return this.http.patch<any>(this.baseUrl + endpoint, params, fullOptions);
  }

  put(
    endpoint: string,
    params: any,
    options?: { headers?: HttpHeaders; params?: HttpParams },
  ): Observable<any> {
    const fullOptions = {
      ...options,
      responseType: "json" as "json",
    };
    return this.http.put<any>(this.baseUrl + endpoint, params, fullOptions);
  }

  getDownloadDocument(
    endpoint: string,
    options?: { headers?: HttpHeaders; params?: HttpParams },
  ): Observable<Blob> {
    const fullOptions = {
      ...options,
      responseType: "blob" as "blob",
    };
    return this.http.get(this.baseUrl + endpoint, fullOptions);
  }

  postDownloadDocument(
    endpoint: string,
    params?: any,
    options?: { headers?: HttpHeaders; params?: HttpParams },
  ): Observable<Blob> {
    const fullOptions = {
      ...options,
      responseType: "blob" as "blob",
    };
    return this.http.post(this.baseUrl + endpoint, params, fullOptions);
  }
}
