import { Routes } from "@angular/router";
import { AppFullComponent } from "./shared/layouts/full/full.component";
import { PaymentSuccessComponent } from "./modules/payment/payment-success/payment-success.component";
import { TopupRequestComponent } from "./modules/topup-request/topup-request.component";
import { UploadDocumentsComponent } from "./modules/upload-documents/upload-documents.component";
import { PaymentComponent } from "./modules/payment/payment.component";

export const APP_ROUTES: Routes = [
  {
    path: "",
    canActivate: [],
    component: AppFullComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/request/request.routes").then(
            (r) => r.REQUEST_ROUTES,
          ),
      },
      {
        path: "payment/response",
        component: PaymentSuccessComponent,
      },
      {
        path: "payment",
        component: PaymentComponent,
      },
      {
        path: "topup-request/:id",
        component: TopupRequestComponent,
      },
      {
        path: "upload-documents/:id",
        component: UploadDocumentsComponent,
      },
      {
        path: "quotation",
        loadChildren: () =>
          import("./modules/quotation/quotation.routes").then(
            (r) => r.QUOTATION_ROUTES,
          ),
      },
      {
        path: "endorsement",
        loadChildren: () =>
          import("./modules/endorsement/endorsement.routes").then(
            (r) => r.ENDORSEMENT_ROUTES,
          ),
      },
    ],
  },
];
