import { Component } from "@angular/core";
import { NgIf } from "@angular/common";
import { ActivatedRoute } from "@angular/router";

// Angular Material
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialog } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

// Services
import { AlertService } from "src/app/shared/services/alert.service";
import { LoadingSpinnerService } from "src/app/shared/services/loading-spinner.service";
import { QuotationService } from "../quotation/_services/quotation.service";
import { TranslateModule } from "@ngx-translate/core";

// Components
import { SampleDialogComponent } from "./_components/sample-dialog/sample-dialog.component";
import { ThankyouDialogComponent } from "./_components/thankyou-dialog/thankyou-dialog.component";

@Component({
  selector: "app-upload-documents",
  templateUrl: "./upload-documents.component.html",
  styleUrls: ["./upload-documents.component.scss"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    NgIf,
    MatCardModule,
    TranslateModule,
    SampleDialogComponent,
  ],
})
export class UploadDocumentsComponent {
  fileToUpload: {
    type: string;
    file: File;
  }[] = [];
  filename: { [key: string]: string } = {};
  id: string;
  data: {}[];

  errorActivatedPolicy: boolean = false;

  constructor(
    private dialog: MatDialog,
    private quotationService: QuotationService,
    private loadingService: LoadingSpinnerService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
  ) {
    this.id = this.activatedRoute.snapshot.params.id;
    this.getData();
  }

  private getData() {
    this.loadingService.loadingOn(true);
    this.quotationService.getPolicyPhotos(this.id).subscribe({
      next: (data) => {
        data.forEach((data) => {
          this.filename[data.result.refType] = data.result.fileName;
        });
        this.loadingService.loadingOff(true);
      },
      error: (err) => {
        this.loadingService.loadingOff(true);
      },
    });
  }

  showSampleDialog(data: string) {
    this.dialog.open(SampleDialogComponent, {
      data: data,
      panelClass: "success-dialog",
    });
  }

  deleteFile(file) {
    this.filename[file] = "";
    this.fileToUpload = this.fileToUpload.filter((value) => value.type != file);
  }

  handleSubmit() {
    if (this.fileToUpload) {
      let fd = new FormData();
      for (let i = 0; i < this.fileToUpload.length; i++) {
        fd.append(`fileList[${i}].file`, this.fileToUpload[i].file);
        fd.append(`fileList[${i}].type`, this.fileToUpload[i].type);
      }

      this.quotationService.uploadPhoto(this.id, fd).subscribe({
        next: (_) => {
          this.dialog.open(ThankyouDialogComponent, {
            panelClass: "success-dialog",
            data: {
              title: "Thank you",
              body: "uploadDocs.thankyouMsg",
            },
          });
        },
        error: (err) => {
          this.alertService.openSnackBar(err.error);
        },
      });
    }
  }

  handleFileInput(files, type) {
    this.fileToUpload.push({
      type: type,
      file: files.target.files[0],
    });

    this.filename[type] = files.target.files[0].name;
    files.target.value = null;
  }
}
