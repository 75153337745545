import { Component, OnInit, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss'],
  standalone: true,
  imports: [
    TranslateModule
  ]
})
export class SectionTitleComponent implements OnInit {
  @Input() title: string;
  @Input() desc: string;

  constructor() {}

  ngOnInit(): void {}
}
