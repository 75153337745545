<div>
  <button mat-icon-button class="dismiss-btn" (click)="dismissDialog()">
    <mat-icon>close</mat-icon>
  </button>

  <a (click)="faqDialog()">
    {{ "floatingMenu.faq" | translate }}
  </a>

  <a (click)="guideDialog()">
    {{ "floatingMenu.guide" | translate }}
  </a>
</div>
