<div class="header">
  <div class="header-container">
    <!-- PS LOGO -->
    <div class="header-logo">
      <a href="/"> <img src="/assets/images/tomove/ps-logo-1.png" alt="" /></a>
    </div>

    <!-- Language, menus, whatsapp -->
    <div
      class="header-menu-container"
      *ngIf="!(screenSizeService.isMobile$ | async)"
    >
      <ng-container [ngTemplateOutlet]="languageMenu"></ng-container>

      <button
        mat-raised-button
        (click)="guideDialog()"
        class="header-container__info-menu"
      >
        <img src="assets/images/tomove/guides.png" alt="" />
        <span>
          {{ "floatingMenu.guide" | translate }}
        </span>
      </button>

      <button
        mat-raised-button
        (click)="faqDialog()"
        class="header-container__info-menu"
      >
        <img src="assets/images/tomove/faq.png" alt="" />
        <span>
          {{ "floatingMenu.faq" | translate }}
        </span>
      </button>

      <button
        mat-raised-button
        (click)="whatsppRedirect()"
        class="header-container__whatsapp-menu"
      >
        <img
          src="https://car.policystreet.app/assets/icons/whatsapp_icon_unfiltered.png"
          alt=""
        />
        <span>WhatsApp</span>
      </button>
    </div>

    <!-- Mobile: Language, menus, whatsapp -->
    <div
      class="header-mobile-container"
      *ngIf="screenSizeService.isMobile$ | async"
    >
      <ng-container [ngTemplateOutlet]="languageMenu"></ng-container>

      <button mat-icon-button (click)="openMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-menu #language="matMenu">
  <button mat-menu-item (click)="switchLanguage(languageConst.en)">
    <span>{{ "language.enShort" | translate }}</span>
  </button>
  <button mat-menu-item (click)="switchLanguage(languageConst.bm)">
    <span>{{ "language.bm" | translate }}</span>
  </button>
</mat-menu>

<ng-template #languageMenu>
  <button
    mat-stroked-button
    [matMenuTriggerFor]="language"
    class="header-container__language-menu"
  >
    <span *ngIf="!translate.currentLang">{{
      "language.enShort" | translate
    }}</span>
    <span *ngIf="isCurrentLangBM">{{ "language.bm" | translate }}</span>
    <span *ngIf="isCurrentLangEN">{{ "language.enShort" | translate }}</span>
    <img src="https://car.policystreet.app/assets/image/language2.png" alt="" />
  </button>
</ng-template>
