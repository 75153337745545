import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { FaqDialogComponent } from "src/app/shared/components/faq-dialog/faq-dialog.component";
import { GuidesDialogComponent } from "src/app/shared/components/guides-dialog/guides-dialog.component";

@Component({
  selector: "app-menu-mobile",
  templateUrl: "./menu-mobile.component.html",
  styleUrls: ["./menu-mobile.component.scss"],
  standalone: true,
  imports: [MatIconModule, TranslateModule, NgIf, MatButtonModule],
})
export class MenuMobileComponent {
  showFAQ: Boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MenuMobileComponent>,
  ) {}

  guideDialog() {
    this.dialogRef.close();
    this.dialog.open(GuidesDialogComponent, {
      panelClass: "guide-dialog",
    });
  }

  dismissDialog() {
    this.dialogRef.close();
  }

  faqDialog() {
    this.dialogRef.close();
    this.dialog.open(FaqDialogComponent, {
      panelClass: "faq-dialog",
    });
  }
}
