import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-expandable-card",
  templateUrl: "./expandable-card.component.html",
  styleUrls: ["./expandable-card.component.scss"],
  standalone: true,
  imports: [
    TranslateModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    NgIf,
  ],
})
export class ExpandableCardComponent implements OnInit {
  @Input() forCarDetails: boolean = false;
  @Input() title: string = "";

  @Output() onEdit = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  edit(e) {
    e.stopPropagation();
    this.onEdit.emit();
  }
}
