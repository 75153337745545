<div
  class="loading-container"
  [ngClass]="{ 'fade-out': !(loadingService.loading$ | async) }"
>
  <ng-lottie
    [options]="animationOptions"
    width="300px"
    height="300px"
  ></ng-lottie>
</div>
